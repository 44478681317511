import React, { useState } from "react";
import { Link } from "gatsby";
import * as stylesShowcase from "../styles/profile.module.css";
import * as navContainer from "../styles/navbar.module.css";
import Masonry from "react-masonry-css";
import Subscribe from "./Subscribe";
import { subscribeForm } from "./helpers";

const Showcase = ({
  data,
  profileData,
  open,
  handleClick,
  showModal,
  setModal,
  setProfile,
  comingSoonData,
  language,
  messages,
}) => {

  const [searchInput, setSearchInput] = useState("");

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const modalData = {
    showModal,
    setModal,
  };
  
  const breakpointColumnsObj = {
    default: 2,
    700: 1,
  };

  const filteredData = Array.isArray(data)
    ? data.filter((user) => {
        return (
          user.data.Status === "Published" &&
          user.data.Language === language &&
          (user.data.Name.toLowerCase().includes(searchInput.toLowerCase()) ||
            user.data.State_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            user.data.Quote.toLowerCase().includes(searchInput.toLowerCase()) ||
            (user.data.Website_Content &&
              user.data.Website_Content.toLowerCase().includes(searchInput.toLowerCase()))
          )
        );
      })
    : [];

   if (!showModal && !open) {
      return (

        <div className={navContainer.navCont}>
          <div className={stylesShowcase.searchBox}>
            <input
              type="text"
              placeholder="Search..."
              value={searchInput}
              onChange={handleSearchChange}
            />
            <button type="reset" onClick={handleSearchChange}></button>
            <br/>
          </div>

          <div className={`${navContainer.navCont}`}>
           <br/>
          </div>
          
          <div className={`${stylesShowcase.showcase} `}>
            <div className={`${navContainer.navCont}`}>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className={stylesShowcase.myMasonryGrid}
                columnClassName={stylesShowcase.myMasonryGrid_column}
              >
                {filteredData &&
                  filteredData.map((user, index) => {
                    if (
                      user.data.Status === "Published" &&
                      user.data.Language === language
                    ) {
                      let pathURL = user.data.Name_English.toLowerCase().trim();
                      pathURL = pathURL.replace(/\s/g, "-");

                      return (
                        <div key={index}>
                          {index === 2 + (data.length % 2) && (
                            <div
                              className={stylesShowcase.comingsoonCard}
                              onClick={() => {
                                setProfile(user);
                              }}
                            >
                              <p className={stylesShowcase.comingsoonText}>
                                {messages.comingSoon}
                              </p>
                              <p className={stylesShowcase.comingsoonText}>
                                {messages.comingSoonDesc}
                              </p>
                              <div className={stylesShowcase.nextStoryContainer}>
                                <div className={stylesShowcase.comingsoonLeftStory}>
                                  <img
                                    src={comingSoonData[0]?.data?.Photo1_URL}
                                    alt={comingSoonData[0]?.data?.Alt_Text_Photo1}
                                    className={stylesShowcase.comingSoonImg}
                                  />
                                  <p>{comingSoonData[0]?.data?.Name}</p>
                                  <p>{comingSoonData[0]?.data?.State_name}</p>
                                </div>

                                <div className={stylesShowcase.comingsoonLeftStory}>
                                  <img
                                    src={comingSoonData[1]?.data?.Photo1_URL}
                                    alt={comingSoonData[1]?.data?.Alt_Text_Photo1}
                                    className={stylesShowcase.comingSoonImg}
                                  />
                                  <p>{comingSoonData[1]?.data?.Name}</p>
                                  <p>{comingSoonData[1]?.data?.State_name}</p>
                                </div>
                              </div>
                              <p className={stylesShowcase.comingsoonText}>
                                {messages.receiveAlerts}
                              </p>
                              <div
                                className={stylesShowcase.comingsoonSubscribe}
                                dangerouslySetInnerHTML={subscribeForm(
                                  true,
                                  language,
                                  messages
                                )}
                              />
                            </div>
                          )}

                          <div
                            onClick={() => {
                              setProfile(user);
                            }}
                          >
                            <Link to={`inclusive-stories/${pathURL}`}>

                              <div className={stylesShowcase.profilesCard}>
                                {index == 0 && (
                                  <div className={stylesShowcase.newStory}>
                                    <span>{messages.newStory}</span>
                                  </div>
                                )}
                                <div className={stylesShowcase.imgWrapper}>
                                  <img
                                    src={user.data.Photo1_URL}
                                    alt={user.data.Alt_Text_Photo1}
                                    className={stylesShowcase.profileCardImg}
                                  />
                                </div>

                                <div className={stylesShowcase.userInfoWrapper}>
                                  <span className={stylesShowcase.userInfoText}>
                                    {user.data.Name}, {user.data.State_name}
                                  </span>
                                </div>
                                <h3 className={stylesShowcase.userQuoteHeader}>
                                  {user.data.Quote}
                                </h3>
                                <p className={stylesShowcase.profileUserStoryText}>
                                  {user.data.Website_Content
                                    ? `${user.data.Website_Content.slice(
                                        0,
                                        255
                                      )}...`
                                    : ``}
                                </p>
                              </div>

                            </Link>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Masonry>
            </div>
          </div>
        </div>
      );
    } else
  return (
    <Subscribe
      showModal={showModal}
      open={open}
      handleClick={handleClick}
      setModal={setModal}
      language={language}
      messages={messages}
    />
  );
};

export default Showcase;
